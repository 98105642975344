<template>
  <div :key="autoKey">
    <b-row>
      <b-col md="12">
        <b-card-actions
          title="Filtros"
          action-collapse
        >
          <div>
            <b-row>
              <!-- Permite master escolher a empresa -->
              <b-col md="3">
                <selecionar-cliente
                  :pesquisa="true"
                  @clienteSelecionado="updateClienteSelecionado"
                  @iniciarPesquisa="updatePesquisa"
                />
              </b-col>
              <b-col md="3">
                <selecionar-gestor
                  :id-do-cliente="idDoCliente"
                  @gestorSelecionado="updateGestorSelecionado"
                />
              </b-col>
              <b-col md="3">
                <selecionar-funcionario
                  :id-do-cliente="idDoCliente"
                  @usuarioSelecionado="updateUsuarioSelecionado"
                />
              </b-col>
              <b-col md="3">
                <selecionar-formacao
                  @formacaoSelecionada="updateFormacaoSelecionada"
                />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="3">
                <label for="datain">Data inicial: </label>
                <b-form-datepicker
                  id="datain"
                  v-model="datain"
                  v-bind="labels[locale] || {}"
                  locale="pt-BR"
                  placeholder="Data"
                  dropright
                  reset-button
                  close-button
                  :max="datafin === null || datafin === '' ? null : datafin"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  }"
                  @input="updateDataIn"
                />
              </b-col>
              <b-col md="3">
                <label for="datafin">Data final: </label>
                <b-form-datepicker
                  id="datafin"
                  v-model="datafin"
                  v-bind="labels[locale] || {}"
                  locale="pt-BR"
                  placeholder="Data"
                  dropright
                  reset-button
                  close-button
                  :min="datain === null || datain === '' ? null : datain"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  }"
                  @input="updateDataFin"
                />
              </b-col>
              <b-col md="3">
                <label>Idade inicial:</label>
                <b-input-group>
                  <b-form-input
                    v-model="idadein"
                    placeholder="Idade inicial"
                    type="number"
                    :disabled="idDoPerfil === 5"
                  />
                  <!-- <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="idDoPerfil === 5"
                      @click="idadein = null"
                    >
                      <feather-icon icon="XIcon" /> 
                    </b-button>
                  </b-input-group-append> -->
                </b-input-group>
              </b-col>
              <b-col md="3">
                <label>Idade final:</label>
                <b-input-group>
                  <b-form-input
                    v-model="idadefin"
                    placeholder="Idade final"
                    type="number"
                    :disabled="idDoPerfil === 5"
                  />
                  <!-- <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="idDoPerfil === 5"
                      @click="idadefin = null"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append> -->
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="3">
                <label>Cidade natal:</label>
                <b-input-group>
                  <b-form-input
                    v-model="cidadeNatal"
                    placeholder="Cidade natal"
                    :disabled="idDoPerfil === 5"
                  />

                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="idDoPerfil === 5"
                      @click="cidadeNatal = null"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>

              <b-col md="3">
                <selecionar-teste @testeSelecionado="updateTesteSelecionado" />
              </b-col>
              <!-- <b-col md="3">
                <label>Media</label>
                <feather-icon
                  v-b-popover.hover.top="
                    'Fórumula = (X1 + X2 + X3 + X4 + X5)/N X = Teste - N = quantidade total de testes realizados'
                  "
                  icon="InfoIcon"
                />
                <b-input-group>
                  <b-form-input
                    v-model="media"
                    type=""
                    :disabled="idDoPerfil === 5"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="idDoPerfil === 5"
                      @click="idadein = null"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col> -->

              <!-- <b-col md="3">
                <label>Desvio Padrão</label>
                <feather-icon
                  v-b-popover.hover.top="
                    'Fórmula: √((X1 - x¯)^2+(X2 - x¯)^2+(X3 - x¯)^2)/(N-1)'
                  "
                  icon="InfoIcon"
                />
                <b-input-group>
                  <b-form-input
                    v-model="idadefin"
                    type=""
                    :disabled="idDoPerfil === 5"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="idDoPerfil === 5"
                      @click="idadefin = null"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col> -->

              <b-col md="3">
                <selecionar-local-de-trabalho
                  :id-do-cliente="idDoCliente"
                  @localSelecionado="updateLocalSelecionado"
                />
              </b-col>
              <b-col md="3">
                <selecionar-cargo
                  :id-do-cliente="idDoCliente"
                  @cargoSelecionado="updateCargoSelecionado"
                />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="3">
                <selecionar-status
                  @statusSelecionado="updateStatusSelecionado"
                />
              </b-col>
              <b-col
                md="3"
                class="buscar"
              >
                <label>Buscar:</label>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  block
                  style="margin-bottom:0;"
                  @click="backendSearch"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card-actions
          title="Média Total"
          action-collapse
        >
          <div>
            <b-col md="2">
              <b-input-group>
                <b-form-input
                  v-model="mediaTotal"
                  type="number"
                  disabled
                />
              </b-input-group>
            </b-col>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          no-actions
        >
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
            :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :sort-options="{
              enabled: true,
              multipleColumns: true,
              initialSortBy: sortTable
            }"
            theme="my-theme"
            @on-sort-change="onSortChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'usuario'"
                class="text-nowrap"
              >
                <b-avatar
                  :src="props.row.avatar"
                  class="mx-1"
                />
                <span>{{ props.row.usuario }}</span>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <a
                    v-b-tooltip.hover.bottom="'Detalhes do Resultado'"
                    :href="
                      '/consultar/resultados/' +
                        props.row.idUsuario +
                        '/' +
                        props.row.tipoTeste
                    "
                    class="icone"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                    />
                  </a>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrando 1 até
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '25', '50', '100']"
                    class="mx-1"
                    @input="onPerPageChange"
                  />
                  <span class="text-nowrap">
                    de {{ totalDeRegistros }} registros
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="paginaAtual"
                    :total-rows="totalDeRegistros"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="onPageChange"
                  >
                    <!-- @input="value => props.pageChanged({ currentPage: value })" -->
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
            <div slot="emptystate">
              Nenhum registro encontrado!
            </div>
          </vue-good-table>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  VBPopover,
  VBTooltip,
  BCard,
  BRow,
  BCol,
  BCardText,
  BLink,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormSelect,
  BFormDatepicker
  // BButton,
} from "bootstrap-vue"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { VueGoodTable } from "vue-good-table"
import store from "@/store/index"
import Ripple from "vue-ripple-directive"
import SelecionarCliente from "@/views/global/SelecionarCliente.vue"
import SelecionarFuncionario from "../global/SelecionarFuncionario.vue"
import SelecionarGestor from "../global/SelecionarGestor.vue"
import SelecionarTeste from "../global/SelecionarTeste.vue"
import SelecionarStatus from "../global/SelecionarStatus.vue"
import SelecionarFormacao from "../global/SelecionarFormacao.vue"
import SelecionarCargo from "../global/SelecionarCargo.vue"
import useJwt from "@/auth/jwt/useJwt"
import { getUserData } from "@/auth/utils"
import SelecionarLocalDeTrabalho from "../global/SelecionarLocalDeTrabalho.vue"

export default {
  components: {
    // BCard,
    BCardActions,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BPagination,
    // BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    SelecionarCliente,
    SelecionarFuncionario,
    SelecionarGestor,
    SelecionarTeste,
    SelecionarStatus,
    SelecionarFormacao,
    SelecionarCargo,
    BFormDatepicker,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    SelecionarLocalDeTrabalho
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover
  },
  data() {
    return {
      autoKey: "consultar-0",
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Empresa",
          field: "empresa",
          width: "200px"
        },
        {
          label: "Local de Trabalho",
          field: "base"
        },
        {
          label: "Região",
          field: "regiao"
        },
        {
          label: "Gestor",
          field: "gestor",
          width: "300px"
        },
        {
          label: "Cargo",
          field: "cargo"
        },
        {
          label: "Jogo",
          field: "teste"
        },
        {
          label: "Usuário",
          field: "usuario",
          width: "300px"
        },
        {
          label: "Quantidade",
          field: "quantidadeDeTeste",
          type: "number"
        },
        {
          label: "Média",
          field: "mediaDeAcerto",
          type: "number"
        },
        {
          label: "Idade",
          field: "idade",
          type: "number"
        },
        {
          label: "Início",
          field: "dataInicio",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss", // 2021-10-05T00:00:00
          dateOutputFormat: "dd/MM/yyyy"
        },
        {
          label: "Fim",
          field: "dataFim",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss",
          dateOutputFormat: "dd/MM/yyyy"
        },
        {
          label: "Status",
          field: "status",
          // formatFn: this.formatarStatus,
          width: "110px",
          sortable: false
        },
        {
          label: "Desvio Padrão",
          field: "desvio"
        },
        {
          label: "Detalhe",
          field: "action",

          width: "110px",
          sortable: false
        }
      ],
      rows: [],
      searchTerm: "",
      locale: "pt-BR",
      labels: {
        "pt-BR": {
          labelPrevDecade: "Voltar década",
          labelPrevYear: "Voltar ano",
          labelPrevMonth: "Voltar mês",
          labelCurrentMonth: "Mês atual",
          labelNextMonth: "Avançar mês",
          labelNextYear: "Avançar ano",
          labelNextDecade: "Avançar década",
          labelToday: "Hoje",
          labelSelected: "Selecionado",
          labelNoDateSelected: "Nenhuma data selecionada",
          labelCalendar: "Calendário",
          labelNav: "Navegação",
          labelHelp: "Utilize os cursores para navegar no calendário",
          labelResetButton: "Limpar",
          labelCloseButton: "Fechar"
        }
      },
      idDoPerfil: this.$store.state.user.idDoPerfil,
      // Dados para enviar
      paginaAtual: 1,
      idDoCliente:
        this.$store.state.user.idDoPerfil === 1
          ? 0
          : this.$store.state.user.idDoCliente,
      idDoUsuario: 0,
      gestor: null,
      teste: 0,
      cargo: 0,
      mediaTotal: 0,
      cidadeNatal: null,
      status: null, // Mandar null se for Ambos
      escolaridade: 0,
      idadein: "",
      idadefin: "",
      datain: null,
      datafin: null,
      idDaBase: 0,
      // Filtros
      sortTable: [
        { field: "usuario", type: "asc" },
        { field: "idade", type: "desc" }
      ],
      totalDeRegistros: 0
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    pageRefresh() {
      return this.$store.state.user.idDoPerfil
    }
  },
  watch: {
    pageRefresh: function(a, b) {
      // console.log("Mudou o id do perfil?")
      // console.log("Old: ", a, " New: ", b)
      this.autoKey = "consultar-" + String(parseInt(Math.random() * 100))
    }
  },
  mounted() {
    this.$refs["loadingCard"].showLoading = true
    this.removeColumns()
  },
  created() {},

  methods: {
    updatePesquisa() {
      if (this.idDoCliente !== null && !Number.isNaN(this.idDoCliente)) {
        this.updateTabela()
      }
    },
    preparaEnvio() {
      const usuarioBasico = perfil =>
        perfil === 5 ? getUserData().idDoUsuario : this.idDoUsuario
      const usuarioParaPesquisar = usuarioBasico(
        JSON.parse(localStorage.userData).idDoPerfil
      )
      // console.log("Testando usuário básico: ", usuarioParaPesquisar)
      let args = {
        filtros: {
          empresa: this.idDoCliente,
          usuario: usuarioParaPesquisar === null ? 0 : usuarioParaPesquisar,
          gestor: this.gestor === null ? 0 : this.gestor,
          teste: this.teste,
          cargo: this.cargo === null ? 0 : this.cargo,
          cidadeNatal: this.cidadeNatal,
          status: this.status,
          escolaridade: this.escolaridade,
          idadeInicial: this.idadein === "" ? 0 : this.idadein,
          idadeFinal: this.idadefin === "" ? 0 : this.idadefin,
          dataInicial: this.datain === "" ? null : this.datain,
          dataFinal: this.datafin === "" ? null : this.datafin,
          idDaBase: this.idDaBase
        },
        sort: this.sortTable,
        pagina: this.paginaAtual,
        quantidadePorPagina: this.pageLength
      }

      return args
    },
    async updateTabela() {
      let resp = await useJwt
        .consultarResultadosTestes(this.preparaEnvio())
        .then(response => response.data)
        .catch(error => {
          // error
          this.MensagemToastConsultaResultadoTeste(
            "warning",
            "top-center",
            error
          )
          this.$refs["loadingCard"].showLoading = false
          return false
        })

      if (resp) {
        // console.log("Resp 2Interno: ", resp)
        resp.resultadoTestes[0].empresa === ""
          ? (this.rows = [])
          : (this.rows = resp.resultadoTestes)
        this.totalDeRegistros = resp.totalDeRegistros
        this.mediaTotal = resp.resultadoTestes[0].mediaTotal
        this.$refs["loadingCard"].showLoading = false
      }
    },
    formatarStatus(status) {
      // console.log("Status: ", status)
      // console.log("Tem I?", status.includes("I:"))
      // console.log("Tem N?", status.includes("N:"))

      const temI = status.includes("I:")
      const temN = status.includes("N:")

      if (temI && temN) {
        const statusAjustado = status.split(",")
        if (statusAjustado[0].includes("I")) {
          return statusAjustado[0] + " " + statusAjustado[1]
        } else {
          return statusAjustado[1] + " " + statusAjustado[0]
        }
      } else if (temI && !temN) {
        return status + " N: 0"
      } else if (!temI && temN) {
        return "I: 0 " + status
      } else {
        return "Erro, verifique com o administrador!"
      }

      return status === "N" ? "Normal" : "Irregular"
    },
    updateDataFin(date) {
      // console.log("Data inicial: ", this.datain)
      // console.log("Data final: ", this.datafin)
    },
    updateDataIn(date) {
      // console.log("Data inicial: ", this.datain)
      // console.log("Data final: ", this.datafin)
    },
    updateCargoSelecionado(cargo) {
      // console.log("Cargo selecionado: ", cargo)
      this.cargo = cargo === null ? null : cargo
    },
    updateLocalSelecionado(local) {
      // console.log("Local selecionado: ", local)
      this.idDaBase = local === null ? 0 : local
    },
    updateFormacaoSelecionada(escolaridade) {
      // console.log("Formação selecionada: ", escolaridade)
      this.escolaridade = escolaridade
    },
    updateStatusSelecionado(status) {
      // console.log("Status selecionado: ", status)
      this.status = status
    },
    updateTesteSelecionado(teste) {
      // console.log("Teste selecionado: ", teste)
      this.teste = teste
    },
    updateClienteSelecionado(cliente) {
      // console.log("Cliente selecionado: ", cliente)
      this.idDoCliente = cliente.idDoCliente
    },
    updateUsuarioSelecionado(usuario) {
      // console.log("Usuário selecionado: ", usuario)
      this.idDoUsuario = usuario === null ? null : usuario
    },
    updateGestorSelecionado(gestor) {
      // console.log("Gestor selecionado: ", gestor)
      this.gestor = gestor === null ? null : gestor
    },
    onSortChange(params) {
      // Quando muda a ordenação
      // console.log("Sort:")
      // console.log(params)
      this.sortTable = params
      this.$refs["loadingCard"].showLoading = true

      if (this.idDoCliente !== null && !Number.isNaN(this.idDoCliente)) {
        this.updateTabela()
      }
    },
    backendSearch() {
      // Quando clica no botão pesquisar
      this.$refs["loadingCard"].showLoading = true

      if (this.idDoCliente !== null && !Number.isNaN(this.idDoCliente)) {
        this.updateTabela()
      }
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: "UserIcon",
          variant: "success"
        }
      })
    },
    onPageChange(value) {
      // Quando clica em um número para mudar de página
      // console.log("value: ", value)
      this.paginaAtual = value
      this.$refs["loadingCard"].showLoading = true

      if (this.idDoCliente !== null && !Number.isNaN(this.idDoCliente)) {
        this.updateTabela()
      }
    },
    onPerPageChange(value) {
      // Quando muda a quantidade de páginas
      // console.log("Nova quantidade por página: ", value)
      this.pageLength = value
      this.$refs["loadingCard"].showLoading = true

      if (this.idDoCliente !== null && !Number.isNaN(this.idDoCliente)) {
        this.updateTabela()
      }
    },
    filterTable() {
      this.$refs["loadingCard"].showLoading = true

      if (this.idDoCliente !== null && !Number.isNaN(this.idDoCliente)) {
        this.updateTabela()
      }
    },
    removeColumns() {
      if (this.idDoPerfil != 1) {
        this.columns.splice("field", 1)
      }
    },
    MensagemToastConsultaResultadoTeste(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Não foi possível realizar a busca.",
            icon: "XCircleIcon",
            variant,
            text: "Erro identificado: " + mensagem
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>
<style scoped>
.buscar {
  margin-left: 50%;
}
.icone {
  margin-left: 10%;
  color: inherit;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
