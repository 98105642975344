<template>
  <div :key="autoKey">
    <label>{{ titulo }}:</label>
    <!-- <vue-autosuggest
      v-if="idDoCliente !== 0 && idDoPerfil <= 4"
      :suggestions="filteredOptions"
      :limit="limit"
      :input-props="inputProps"
      :get-suggestion-value="getSuggestionValue"
      @selected="onSelected"
      @input="onInputChange"
    >
      <template slot-scope="{ suggestion }">
        <span class="my-suggestion-item">{{
          suggestion.item.descricaoDaFuncao
        }}</span>
      </template>
    </vue-autosuggest> -->
    <!-- <b-form-select
      v-if="idDoCliente !== 0 && idDoPerfil <= 4"
      v-model="selected"
      :options="listaDeCargos"
      @change="onSelected"
    /> -->
    <v-select
      v-if="idDoCliente !== 0 && idDoPerfil <= 4"
      id="cargo"
      v-model="selected"
      :options="listaDeCargos"
      label="text"
      placeholder="Todos os cargos"
      @input="onSelected"
    >
      <span slot="no-options">
        Opção não encontrada.
      </span>
    </v-select>
    <b-form-input
      v-else-if="idDoCliente !== 0 && idDoPerfil === 5"
      :placeholder="cargoLogado.descricaoDaFuncao"
      disabled
    />
    <b-form-input
      v-else
      placeholder="Todos os cargos"
      disabled
    />
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt"
import { VueAutosuggest } from "vue-autosuggest"
import { BFormInput, BFormSelect } from "bootstrap-vue"
import { getUserData } from "@/auth/utils"
import vSelect from "vue-select"

export default {
  components: {
    // VueAutosuggest,
    BFormInput,
    // BFormSelect
    vSelect
  },
  props: {
    titulo: {
      type: String,
      default: "Cargo"
    },
    idDoCliente: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      inputProps: {
        id: "cargo_autosuggest__input",
        class: "form-control",
        placeholder: "Pesquisar " + this.titulo.toLowerCase()
      },
      idDoPerfil: this.$store.state.user.idDoPerfil,
      datasuggest: [],
      filteredOptions: [],
      limit: 20,
      selected: null,
      listaDeCargos: [],
      autoKey: "cargoKey-0",
      cargoLogado: {
        descricaoDaFuncao: "",
        idDaFuncao: 0,
        idDoCliente: 0
      }
    }
  },
  watch: {
    idDoCliente: function(idDoClienteNew, idDoClienteOld) {
      // console.log("Selected: ", this.selected)
      // Fica observando se o idDoCliente mudou no elemento pai
      // Caso sim, reseta o campo
      this.clearFilters(idDoClienteNew)
      // console.log("newValue: %s, previousValue: %s", newValue, oldValue)
    }
  },
  mounted() {
    if (this.idDoCliente !== 0 && !Number.isNaN(this.idDoCliente)) {
      this.getlistaDeCargos()
    }
  },
  methods: {
    clearFilters(idDoCliente) {
      this.datasuggest = []
      this.filteredOptions = []
      this.listaDeCargos = []
      this.selected = null
      this.$emit("cargoSelecionado", this.selected)
      this.autoKey = "cargoKey-" + String(parseInt(Math.random() * 100))
      if (idDoCliente !== 0 && !Number.isNaN(this.idDoCliente)) {
        this.getlistaDeCargos()
      }
      this.onInputChange(" ")
    },
    onSelected(item) {
      // Esse é o resultado que foi selecionado na listaDeCargos
      this.selected = item
      if (item == null) {
        this.selected = {
          idDoCliente: 0,
          value: 0,
          text: "Todos os cargos"
        }
      }
      this.$emit("cargoSelecionado", this.selected.value)
      // console.log("Selecionado: ", this.selected)
    },
    getSuggestionValue(suggestion) {
      // Esse é o resultado retornado para o input quando
      // o master clica em uma opção da listaDeCargos
      return suggestion.item.descricaoDaFuncao
    },
    onInputChange(text) {
      // Essa função faz o filtro ao iniciar a pesquisa
      if (text === "" || text === undefined || text === null) {
        // console.log("Cargo vazio")
        this.clearFilters(this.idDoCliente)
        return
      }
      // console.log("pesquisa cargo: ", text)
      // console.log("idDoCliente: ", this.idDoCliente)
      // console.log("this.listaDeCargos: ", this.listaDeCargos)

      const cargosFiltrados = this.listaDeCargos
        .filter(
          item =>
            item.descricaoDaFuncao
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(
                text
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              ) > -1 && item.idDoCliente === this.idDoCliente
        )
        .slice(0, this.limit)

      this.filteredOptions = [
        {
          data: cargosFiltrados
        }
      ]
    },
    async getlistaDeCargos() {
      // Preenche a listaDeCargos com id e nome do cliente
      const resp = await useJwt
        .listRolesCliente(this.idDoCliente)
        .then(response => response.data)
        .catch(response => {
          response.data
        })

      if (resp.sucesso) {
        this.listaDeCargos = [
          { idDoCliente: 0, value: 0, text: "Todos os cargos" }
        ]
        // this.listaDeCargos = resp.dados.listaFuncao
        // this.onInputChange(" ")
        this.listaDeCargos = []
        resp.dados.listaFuncao.forEach(element => {
          this.listaDeCargos.push({
            idDoCliente: element.idDoCliente,
            value: element.idDaFuncao,
            text: element.descricaoDaFuncao
          })
        })
        // console.log("this.listaDeCargos: ", this.listaDeCargos)

        if (this.idDoPerfil === 5) {
          this.cargoLogado = this.listaDeCargos.find(
            x => x.idDaFuncao === getUserData().idDaFuncao
          )
          this.$emit("cargoSelecionado", this.cargoLogado)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao listar cargos, Por favor, verifique sua conexão com a internet e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
