<template>
  <div>
    <label>Formação:</label>
    <!-- <b-form-select
      v-if="idDoPerfil <= 4"
      v-model="selected"
      :options="options"
      @change="onSelected"
    /> -->
    <v-select
      v-if="idDoPerfil <= 4"
      id="selected"
      v-model="selected"
      :options="options"
      label="text"
      placeholder="Todas as formações"
      @input="onSelected"
    >
      <span slot="no-options">
        Opção não encontrada.
      </span>
    </v-select>
    <b-form-input
      v-else
      :placeholder="retornaFormacao()"
      disabled
    />
  </div>
</template>

<script>
import { BFormSelect, BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue"
import { getUserData } from "@/auth/utils"
import vSelect from "vue-select"

export default {
  components: {
    // BFormSelect,
    BFormInput,
    vSelect
  },
  data() {
    return {
      idDoPerfil: this.$store.state.user.idDoPerfil,
      selected: null,
      options: [
        { value: 0, text: "Todas as formações" },
        { value: 1, text: "Fundamental incompleto" },
        { value: 2, text: "Fundamental cursando" },
        { value: 3, text: "Fundamental completo" },
        { value: 4, text: "Ensino médio incompleto" },
        { value: 5, text: "Ensino médio cursando" },
        { value: 6, text: "Ensino médio concluído" },
        { value: 7, text: "Ensino superior incompleto" },
        { value: 8, text: "Ensino superior cursando" },
        { value: 9, text: "Ensino superior completo" }
      ]
    }
  },

  methods: {
    retornaFormacao() {
      const userData = getUserData()
      const formacao = this.options.find(x => x.value === userData.idDaFormacao)
      // console.log("formacao: ", formacao.text)
      this.$emit("formacaoSelecionada", formacao.value)
      return formacao.text
    },
    onSelected(item) {
      if (item == null) {
        this.selected = {
          value: 0,
          text: "Todos as formações"
        }
      }
      this.$emit("formacaoSelecionada", this.selected.value)
    }
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
