<template>
  <div :key="autoKey">
    <label>{{ titulo }}:</label>
    <!-- <vue-autosuggest
      v-if="idDoCliente !== 0 && idDoPerfil <= 4"
      :suggestions="filteredOptions"
      :limit="limit"
      :input-props="inputProps"
      :get-suggestion-value="getSuggestionValue"
      @selected="onSelected"
      @input="onInputChange"
    >
      <template slot-scope="{ suggestion }">
        <span class="my-suggestion-item">{{
          suggestion.item.nomeDoGestor
        }}</span>
      </template>
    </vue-autosuggest> -->
    <!-- <b-form-select
      v-if="idDoCliente !== 0 && idDoPerfil <= 4"
      v-model="selected"
      :options="gestoresFiltrados"
      @change="onSelected"
    /> -->
    <v-select
      v-if="idDoCliente !== 0 && idDoPerfil <= 3"
      id="gestor"
      v-model="selected"
      :options="gestoresFiltrados"
      label="text"
      placeholder="Todos os gestores"
      @input="onSelected"
    >
      <span slot="no-options">
        Opção não encontrada.
      </span>
    </v-select>

    <b-form-input
      v-else-if="idDoCliente !== 0 && idDoPerfil > 3"
      :placeholder="usuarioLogadoGestor.text"
      disabled
    />
    <b-form-input
      v-else
      placeholder="Todos os gestores"
      disabled
    />
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt"
import { VueAutosuggest } from "vue-autosuggest"
import { BFormInput, BFormSelect } from "bootstrap-vue"
import vSelect from "vue-select"
import { getUserData } from "@/auth/utils"

export default {
  components: {
    // VueAutosuggest,
    BFormInput,
    // BFormSelect,
    vSelect
  },
  props: {
    titulo: {
      type: String,
      default: "Gestor"
    },
    idDoCliente: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      inputProps: {
        id: "gestor_autosuggest__input",
        class: "form-control",
        placeholder: "Pesquisar " + this.titulo.toLowerCase()
      },
      idDoPerfil: this.$store.state.user.idDoPerfil,
      datasuggest: [],
      filteredOptions: [],
      gestorFil: [],
      limit: 20,
      selected: null,
      listaDeGestores: [],
      gestoresFiltrados: [
        {
          idDoCliente: 0,
          value: 0,
          text: "Todos os gestores"
        }
      ],

      autoKey: "gestorKey-0",
      usuarioLogadoGestor: {
        idDoCliente: 0,
        value: 0,
        text: "Todos os gestores"
      }
    }
  },
  watch: {
    idDoCliente: function(newValue, oldValue) {
      // console.log("Selected: ", this.selected)
      // Fica observando se o idDoCliente mudou no elemento pai
      // Caso sim, reseta o campo
      this.clearFilters()
      // console.log("newValue: %s, previousValue: %s", newValue, oldValue)
    }
  },
  mounted() {
    this.getlistaDeGestores()
  },
  methods: {
    clearFilters() {
      this.selected = null
      this.$emit("gestorSelecionado", this.selected)
      this.autoKey = "gestorKey-" + String(parseInt(Math.random() * 100))
      this.FilterBaseIdDoCliente()
      // this.onInputChange(" ")
    },
    onSelected(item) {
      // Esse é o resultado que foi selecionado na listaDeGestores
      this.selected = item
      if (item == null) {
        this.selected = {
          idDoCliente: 0,
          value: 0,
          text: "Todos os gestores"
        }
      }
      this.$emit("gestorSelecionado", this.selected.value)
      // console.log("Selecionado: ", this.selected)
    },
    getSuggestionValue(suggestion) {
      // Esse é o resultado retornado para o input quando
      // o master clica em uma opção da listaDeGestores
      return suggestion.item.nomeDoGestor
    },
    FilterBaseIdDoCliente() {
      this.gestorFil = this.listaDeGestores.filter(
        item => item.idDoCliente === this.idDoCliente
      )
      this.gestoresFiltrados = []
      this.gestorFil.forEach(e =>
        this.gestoresFiltrados.push({
          idDoCliente: e.idDoCliente,
          text: e.text,
          value: e.value
        })
      )
    },

    onInputChange(text) {
      // Essa função faz o filtro ao iniciar a pesquisa
      if (text === "" || text === undefined || text === null) {
        this.clearFilters()
        // console.log("Gestor vazio")
        return
      }
      // console.log("ref", this.$parent.$refs.pageLength)
      // console.log("pesquisa gestor: ", text)
      // console.log("idDoCliente: ", this.idDoCliente)
      // console.log("this.listaDeGestores: ", this.listaDeGestores)
      this.gestoresFiltrados = this.listaDeGestores
        .filter(
          item =>
            item.text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(
                text
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              ) > -1 && item.idDoCliente === this.idDoCliente
        )
        .slice(0, this.limit)

      this.filteredOptions = [
        {
          data: this.gestoresFiltrados
        }
      ]
    },
    async getlistaDeGestores() {
      // Preenche a listaDeGestores com id e nome do cliente
      const resp = await useJwt
        .getGestor()
        .then(response => response.data)
        .catch(response => {
          response.data
        })

      if (resp.sucesso) {
        Object.entries(resp.dados.usuarios).filter(e => {
          e.idDaEmpresa === this.idDoCliente
          this.listaDeGestores.push({
            idDoCliente: e[1].idDaEmpresa,
            value: e[1].idDoGestor,
            text: e[1].nomeDoGestor
          })
        })

        if (this.idDoPerfil === 5) {
          const gestor = this.listaDeGestores.find(
            x => x.value === getUserData().idDoGestor
          )
          // console.log("Lista de Gestores: ", this.listaDeGestores)
          if (
            this.usuarioLogadoGestor !== "" &&
            this.usuarioLogadoGestor !== undefined &&
            this.usuarioLogadoGestor !== null &&
            this.usuarioLogadoGestor.idDoGestor !== 0
          ) {
            this.usuarioLogadoGestor = gestor
            this.$emit("gestorSelecionado", this.usuarioLogadoGestor)
          }
        } else if (this.idDoPerfil === 4) {
          const clientelogado = this.listaDeGestores.find(
            x => x.value === getUserData().idDoUsuario
          )
          console.log("clienteLogado: ", clientelogado)

          this.usuarioLogadoGestor = clientelogado
          console.log("usuarioLogadoGestor: ", this.usuarioLogadoGestor)

          this.$emit("gestorSelecionado", this.usuarioLogadoGestor.value)
        }
        this.FilterBaseIdDoCliente()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao listar gestores, Por favor, verifique sua conexão com a internet e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
