<template>
  <div>
    <label>Jogo:</label>
    <!-- <b-form-select
      v-model="selected"
      :options="options"
      @change="onSelectUpdate"
    /> -->
    <v-select
      id="selected"
      v-model="selected"
      :options="options"
      label="text"
      placeholder="Todos"
      @input="onSelectUpdate"
    >
      <span slot="no-options">
        Opção não encontrada.
      </span>
    </v-select>
  </div>
</template>

<script>
import { BFormSelect } from "bootstrap-vue"
import vSelect from "vue-select"

export default {
  components: {
    // BFormSelect
    vSelect
  },
  data() {
    return {
      selected: 0,
      options: [
        { value: 0, text: "Todos" },
        { value: 1, text: "Atenção" },
        // { value: 2, text: "Memória" }
      ]
    }
  },
  mounted() {
    this.$emit("testeSelecionado", this.selected)
  },
  methods: {
    onSelectUpdate(item) {
      if (item == null) {
        this.selected = { value: 1, text: "Atenção" }
      }

      this.$emit("testeSelecionado", this.selected.value)
    }
  }
}
</script>
