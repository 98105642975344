<template>
  <div>
    <!-- Esse componente tem como objetivo servir para selecionar o status do desvio -->
    <label>Status:</label>
    <!-- <b-form-select
      v-model="selected"
      :options="options"
      @change="onSelected"
    /> -->
    <v-select
      id="selected"
      v-model="selected"
      :options="options"
      label="text"
      placeholder="Todos"
      @input="onSelected"
    >
      <span slot="no-options">
        Opção não encontrada.
      </span>
    </v-select>
  </div>
</template>

<script>
import { BFormSelect } from "bootstrap-vue"
import vSelect from "vue-select"

export default {
  components: {
    // BFormSelect,
    vSelect
  },
  data() {
    return {
      selected: null,
      options: [
        { value: "C", text: "Cuidado" },
        { value: "I", text: "Inapto" },
        { value: "A", text: "Apto" },
        { value: null, text: "Todos" }
      ]
    }
  },
  methods: {
    onSelected(item) {
      if (item == null) {
        this.selected = {
          value: null,
          text: "Todos"
        }
      }
      this.$emit("statusSelecionado", this.selected.value)
    }
  }
}
</script>
