// Local de trabalho / Base do usuário
<template>
  <div :key="autoKey">
    <label>{{ titulo }}:</label>
    <!-- <vue-autosuggest
      v-model="iniciaSelecionado"
      :suggestions="filteredOptions"
      :limit="limit"
      :input-props="inputProps"
      :get-suggestion-value="getSuggestionValue"
      @selected="onSelected"
      @input="onInputChange"
    >
      <template slot-scope="{ suggestion }">
        <span class="my-suggestion-item">{{ suggestion.item.titulo }}</span>
      </template>
    </vue-autosuggest> -->
    <!-- <b-form-select
      v-if="idDoCliente !== 0 && idDoPerfilLogado <= 4"
      v-model="selected"
      :options="locaisFiltrados"
      @change="onSelected"
    /> -->
    <v-select
      v-if="idDoCliente !== 0 && idDoPerfilLogado <= 4"
      id="localTrabalho"
      v-model="selected"
      :options="locaisFiltrados"
      label="text"
      placeholder="Todos os locais"
      @input="onSelected"
    >
      <span slot="no-options">
        Opção não encontrada.
      </span>
    </v-select>

    <b-form-input
      v-else
      placeholder="Todos os locais"
      disabled
    />
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt"
import { VueAutosuggest } from "vue-autosuggest"
import { getUserData } from "@/auth/utils"
import { BFormInput, BFormSelect } from "bootstrap-vue"
import vSelect from "vue-select"

export default {
  components: {
    // VueAutosuggest
    // BFormSelect,
    BFormInput,
    vSelect
  },
  props: {
    titulo: {
      type: String,
      default: "Local de Trabalho"
    },
    idDoCliente: {
      type: Number,
      default: 0
    },
    iniciarBloqueado: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      inputProps: {
        id: "local_autosuggest__input",
        class: "form-control",
        placeholder: "Pesquisar " + this.titulo.toLowerCase(),
        disabled: this.iniciarBloqueado
      },
      iniciaSelecionado: null,
      idDoPerfilLogado: getUserData().idDoPerfil,
      idDoUsuarioLogado: getUserData().idDoUsuario,
      idDoClienteLogado: getUserData().idDoCliente,
      idBaseLogado: getUserData().idDaBase,
      datasuggest: [],
      filteredOptions: [],
      limit: 20,
      selected: null,
      listaDeLocais: [],
      localFil: [],
      locaisFiltrados: [
        { idDoCliente: 0, value: 0, text: "Todos os locais de trabalho" }
      ],
      autoKey: "localKey-0"
    }
  },
  watch: {
    idDoCliente: function(idDoClienteNew, idDoClienteOld) {
      // console.log("Selected: ", this.selected)
      // Fica observando se o idDoCliente mudou no elemento pai
      // Caso sim, reseta o campo
      this.clearFilters(idDoClienteNew)
      // console.log("newValue: %s, previousValue: %s", newValue, oldValue)
    }
  },
  mounted() {
    if (this.idDoCliente !== 0 && !Number.isNaN(this.idDoCliente)) {
      this.getlistaDeLocais()
    }
  },
  methods: {
    clearFilters(idDoCliente) {
      this.datasuggest = []
      this.filteredOptions = []
      this.listaDeLocais = []
      this.selected = null
      this.$emit("localSelecionado", this.selected)
      this.autoKey = "cargoKey-" + String(parseInt(Math.random() * 100))
      if (idDoCliente !== 0 && !Number.isNaN(this.idDoCliente)) {
        this.getlistaDeLocais()
      }
      // this.onInputChange(" ")
    },
    onSelected(item) {
      // Esse é o resultado que foi selecionado na listaDeLocais
      this.selected = item
      if (item == null) {
        this.selected = {
          idDoCliente: 0,
          value: 0,
          text: "Todos os locais"
        }
      }
      this.$emit("localSelecionado", this.selected.value)
      // console.log("Selecionado: ", this.selected)
    },
    getSuggestionValue(suggestion) {
      // Esse é o resultado retornado para o input quando
      // o master clica em uma opção da listaDeLocais
      return suggestion.item.titulo
    },
    FilterBaseIdDoCliente() {
      this.localFil = this.listaDeLocais.filter(
        item => item.idDoCliente === this.idDoCliente
      )
      this.locaisFiltrados = []
      this.localFil.forEach(e =>
        this.locaisFiltrados.push({
          idDoCliente: e.idDoCliente,
          text: e.text,
          value: e.value
        })
      )
    },
    onInputChange(text) {
      // Essa função faz o filtro ao iniciar a pesquisa
      if (text === "" || text === undefined || text === null) {
        // console.log("Cargo vazio")
        this.clearFilters(this.idDoCliente)
        return
      }
      // console.log("pesquisa cargo: ", text)
      // console.log("idDoCliente: ", this.idDoCliente)
      // console.log("this.listaDeLocais: ", this.listaDeLocais)

      const locaisFiltrados = this.listaDeLocais
        .filter(
          item =>
            item.titulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(
                text
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              ) > -1 && item.idDoCliente === this.idDoCliente
        )
        .slice(0, this.limit)

      this.filteredOptions = [
        {
          data: locaisFiltrados
        }
      ]
    },
    async getlistaDeLocais() {
      // Preenche a listaDeLocais com id e nome do cliente
      const resp = await useJwt
        .listarLocal()
        .then(response => response.data)
        .catch(error => {
          console.error("Erro ao buscar lista de locais: ", error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao listar locais!",
              text: "O seguinte erro ocorreu: " + error,
              icon: "ErrorIcon",
              variant: "danger"
            }
          })
        })

      if (resp.sucesso) {
        // Limpa lista
        this.listaDeLocais = []
        // Preenche com dados do backend
        // this.listaDeLocais = resp.dados.rows
        resp.dados.rows.forEach(element => {
          this.listaDeLocais.push({
            idDoCliente: element.idDoCliente,
            value: element.id,
            text: element.titulo
          })
        })

        // this.onInputChange(" ")
        // console.log("this.listaDeLocais: ", this.listaDeLocais)
        if (
          this.idDoCliente !== 0 && // Habilita se recebeu idDoCliente
          !Number.isNaN(this.idDoCliente) &&
          this.idDoPerfilLogado <= 4 // Habilita se não for usuário básico
        ) {
          this.inputProps.disabled = false
        } else {
          this.inputProps.disabled = true
        }

        if (this.idDoPerfilLogado === 5) {
          // console.log(this.listaDeLocais)
          if (this.idBaseLogado !== 0) {
            const local = this.listaDeLocais.find(
              local => local.idDaBase === this.idBaseLogado
            )
            this.iniciaSelecionado = local.titulo
          } else {
            this.iniciaSelecionado = "N/A"
          }
        }
        this.FilterBaseIdDoCliente()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
